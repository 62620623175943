import action from "../assets/action.svg";
const data = {
  subTitle: "Our Services",
  title: "Slogan",
  features: [
    {
      id: 1,
      imgSrc: action,
      altText: "title",
      title: "title",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 2,
      imgSrc: action,
      altText: "title",
      title: "title",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ],
};

export default data;
